import React from 'react';
import './Resume.css';
// import { Document, Page } from 'react-pdf';

const Resume = () => {
    return (
        <div>
            <iframe title="resume" src="https://tackc.github.io/resume-template/" frameborder="0"></iframe>
        </div>
    )
}

export default Resume;
